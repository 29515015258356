import { configureStore } from '@reduxjs/toolkit';
import analyticsReducer from './slices/analytics';
import authReducer from './slices/auth';
import billingReducer from './slices/billing';
import genericReducer from './slices/generic';
import settingsReducer from './slices/settings';
import teamReducer from './slices/team';
import toolsReducer from './slices/tools';
import menusReducer from './slices/menus';
import modalsReducer from './slices/modals';
import { storage } from '../util';

export const appStateKey = 'app_state';

// middlewares
const localStorageMiddleware = ({ getState }: any) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    storage.set(appStateKey, getState());
    return result;
  }
}

const reHydrateStore = () => {
  const appState = storage.get(appStateKey) as any;
  // TODO: create mechanism to skip reducers from being synced with localStorage
  if (appState?.menus) {
    appState.menus.mobile = false;
  }

  if (appState?.modals) {
    appState.modals.type = null;
    appState.modals.props = {};
  }

  if (appState !== null) {
    return appState;
  }
}

export const store = configureStore({
  reducer: {
    analytics: analyticsReducer,
    auth: authReducer,
    billing: billingReducer,
    generic: genericReducer,
    settings: settingsReducer,
    team: teamReducer,
    tools: toolsReducer,
    menus: menusReducer,
    modals: modalsReducer
  },
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(localStorageMiddleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;