import React from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Spinner } from '../spinner';
import { EmptyChart } from './empty-chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IBarChartDataSet {
  label: string;
  data: number[],
  backgroundColor: string;
}

interface IBarChartProps {
  datasets?: IBarChartDataSet[];
  isLoading?: boolean;
  labels?: string[];
}

export const BarChart = ({ isLoading = false, labels = [], datasets = [] }: IBarChartProps): JSX.Element => {
  const getChartsMaxLimit = (metricsData: IBarChartDataSet[] = []): number => {
    const maxValueAmongstMetrics = metricsData.map((dataset: IBarChartDataSet) => {
      return dataset?.data?.reduce((a: number, b: number) => {
        return Math.max(a, b);
      }, 0);
    });
    return Math.max(...maxValueAmongstMetrics);
  };

  const chartMaxMetric = getChartsMaxLimit(datasets);
  const chartMax = Math.ceil(chartMaxMetric + (chartMaxMetric * 0.1));

  const options = {
    responsive: true,
    scales: {
      y: {
        max: chartMax,
        ticks: {
          padding: 20,
          crossAlign: 'near' as any,
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
  };

  const generateChartData = (): { labels: string[], datasets: IBarChartDataSet[] } => {
    return {
      labels,
      datasets,
    };
  }

  const isChartHasNoDataToVisualize = datasets
    .map((dataset: IBarChartDataSet) => dataset.data)
    .map((array) => array.filter(item => item !== 0))
    .filter(e => e.length)
    .length > 0;

  if (!isChartHasNoDataToVisualize) {
    return <EmptyChart />;
  }

  return (
    <div className={`bar-chart-container ${isLoading ? 'fetching' : ''}`}>
      {isLoading && <Spinner size="large" label="Building chart..." />}
      {(!isLoading && isChartHasNoDataToVisualize) && (
        <Bar options={options} data={generateChartData()} />
      )}
    </div>
  )
};

BarChart.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.number),
    backgroundColor: PropTypes.string.isRequired,
  })),
  labels: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool
};