import React, { forwardRef, FocusEvent } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormInput } from '../../../ui';
import './discount-form.scss';

const baseClass = 'discount-form';

interface IDiscountFormData {
  discount: string;
}

interface IDiscountFormProps {
  isLoading?: boolean;
  onSubmit: (values: IDiscountFormData) => void;
}

const validationScheme = yup.object({
  discount: yup.string().required()
}).required();

export const DiscountForm = forwardRef<HTMLFormElement, IDiscountFormProps>(({ onSubmit, isLoading = false }, ref): JSX.Element => {
  const form = useForm<IDiscountFormData>({
    mode: 'onBlur',
    defaultValues: {
      discount: ''
    },
    resolver: yupResolver(validationScheme),
  });
  const { control, setValue, handleSubmit, formState: { errors } } = form;

  return (
    <form ref={ref} className={baseClass} onSubmit={handleSubmit(onSubmit)}>
      <span className={`${baseClass}__description`}>
        You can use the promo code for your account or your team for all licenses.
        Licenses that were purchased before the promo code was entered cannot receive a discount.
      </span>
      <Controller
        control={control}
        name="discount"
        render={({ field: { name, onBlur, value, ref } }) => (
          <FormInput
            ref={ref}
            placeholder="Please enter your promo code"
            error={errors?.discount?.message}
            name={name}
            value={value}
            disabled={isLoading}
            onBlur={onBlur}
            onChange={(e: FocusEvent<HTMLInputElement>) => setValue('discount', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
          />
        )}
      />
    </form>
  );
});

DiscountForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};