import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaLongArrowAltRight } from 'react-icons/fa';
import * as yup from 'yup';
import { FormButton, FormSelect } from '../../../ui';
import './company.scss';

interface ICompanyFormData {
  country: string;
}

const validationScheme = yup.object({
  country: yup.string().required()
}).required();

export const Company = (): JSX.Element => {
  const [selectedCountry, setSelectedCountry] = useState<string>('');

  const form = useForm<ICompanyFormData>({
    mode: 'all',
    defaultValues: {
      country: '',
    },
    resolver: yupResolver(validationScheme),
  });
  const { register, handleSubmit, setValue, formState: { errors, dirtyFields } } = form;

  const handleFormSubmit = (formFields: ICompanyFormData): void => {
    console.log('formFields = ', formFields);
  }

  const handleCountryChange = (country: string): void => {
    setValue('country', country, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    setSelectedCountry(country);
  }

  const isFormValid = Object.keys(dirtyFields).length > 0 && Object.keys(errors).length === 0;

  return (
    <div className="company">
      <div className="company__heading">
        <span className="company__heading-title">upwork</span>
        <span className="company__heading-title">pipedrive</span>
      </div>
      <div className="company__content">
        <h2 className={"company__title"}>Personal information</h2>
        <form className="company__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="company__field">
            <span className="company__label">Job ID</span>
            <div className="company__field-wrap">
              <FaLongArrowAltRight />
              <FormSelect
                {...register('country')}
                placeholder="Please select country"
                error={errors?.country?.message}
                value={selectedCountry}
                options={[{ value: '', label: '' }, { value: 'ua', label: 'Ukraine' }]}
                onChange={handleCountryChange}
              />
            </div>
          </div>
          <FormButton className="company__button" type="primary" htmlType="submit" disabled={!isFormValid}>Save</FormButton>
        </form>
      </div>
    </div>
  );
}