import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cookie } from "../../util";

const INITIAL_STATE = {
  checkedRelease: cookie.get("newReleaseChecked") || "",
  extensionInstalled: undefined,
  oldExtensionVersion: undefined,
};

export const genericSlice = createSlice({
  name: "generic",
  initialState: INITIAL_STATE,
  reducers: {
    update: (
      state = INITIAL_STATE,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => ({ ...INITIAL_STATE }),
  },
});

// this is for dispatch
export const storeGenericActions = genericSlice.actions;

// this is for configureStore
const genericReducer = genericSlice.reducer;

export default genericReducer;
