import React, { useState } from "react";
import { usersApi } from "../../api";
import { IResponseError } from "../../data-access";
import { FormButton, BrandLogo } from "../../ui";
import { notify, handleFailedRequest } from "../../util";
import "./login.scss";
import { ComingSoon } from "./coming-soon";

export const Login = (): JSX.Element => {
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);

  const handleFormSubmit = (): void => {
    setIsProcessingRequest(true);

    usersApi
      .authenticate("formFields.email")
      .then(() => {
        notify("success", "Please check your email to confirm the login");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessingRequest(false));
  };

  return (
    <div className="login__container">
      <div className="login__content left-side">
        <BrandLogo />

        <div className="login__info">
          <h1 className="login__title">
            Welcome to <b>Uboost.</b>
          </h1>
          <p className="login__description">
            Boost your Upwork with personal assistant.
          </p>
          <ComingSoon />
          {/* <div className="login__auth">
            <FormButton
              className="login__button"
              type="primary"
              htmlType="submit"
              loading={isProcessingRequest}
              disabled={isProcessingRequest}
              onClick={handleFormSubmit}
            >
              Continue with Google
            </FormButton>

            <span className="login__footer">
              By registration you agree to Terms of Use and Privacy Policy
            </span>
          </div> */}
        </div>
      </div>
      <div className="login__content right-side">
        <img src="/login-bg.png" alt="Uboost.io" />
      </div>
    </div>
  );
};
