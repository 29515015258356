import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICrmSettings, IJobScoreSettings } from '../../data-access';

const INITIAL_STATE = {
  crm: {},
  jobScore: {}
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {
    setCrmSettings: (state = INITIAL_STATE, action: PayloadAction<ICrmSettings>) => {
      return {
        ...state,
        crm: {
          ...action.payload
        }
      }
    },
    setJobScoreSettings: (state = INITIAL_STATE, action: PayloadAction<IJobScoreSettings>) => {
      return {
        ...state,
        jobScore: {
          ...action.payload
        }
      }
    },
    resetSettingsData: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeSettingsActions = settingsSlice.actions;

// this is for configureStore
const settingsReducer = settingsSlice.reducer;

export default settingsReducer;