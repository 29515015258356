import { appConfig } from '../../app-config';

export const sanitizeEmail = (email: string): string => {
  let sanitizedEmail = email.replace(/\+/g, '');
  if (appConfig.scumFilterDomain) {
    appConfig.scumFilterDomain.forEach((emailDomain: string) => {
      sanitizedEmail = sanitizedEmail.replace(emailDomain, '');
    });
  }

  return sanitizedEmail;
}