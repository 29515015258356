import { useSelector } from "react-redux";
import { IAuthResponse } from "../../data-access";
import { checkIfEmptyObject, logoutUser } from "../../util";

export const useUser = () => {
  const authUser = useSelector(
    (state: { auth: IAuthResponse }) => state.auth?.user || {}
  );
  const auth = useSelector(
    (state: { auth: IAuthResponse }) => state.auth || {}
  );

  const adminRoles = ["owner", "admin"];

  const resetUser = (): void => {
    logoutUser();
  };

  return {
    currentUser: !checkIfEmptyObject(authUser) ? authUser : null,
    auth: !checkIfEmptyObject(auth) ? auth : null,
    resetUser,
    hasAdminRights: adminRoles.includes(authUser.role),
  };
};
