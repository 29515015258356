import PropTypes from 'prop-types';
import { Spin } from 'antd';
import './spinner.scss';

interface ISpinnerProps {
  size?: 'small' | 'large',
  label?: string;
}

export const Spinner = ({ size = 'small', label = '' }: ISpinnerProps): JSX.Element => {
  return (
    <div className="spinner">
      <Spin size={size} />
      {label}
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
};