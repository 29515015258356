import dayjs, { Dayjs } from 'dayjs';

export const getDatesInRange = (from: string, to: string): string[] => {
  const dateFormat = 'DD-MM-YYYY';

  const datesRange = [];
  let currentDate = dayjs(from, dateFormat);
  const toDate = dayjs(to, dateFormat)

  while (currentDate.diff(toDate) <= 0) {
    datesRange.push(currentDate);
    currentDate = currentDate.add(1, 'days');
  }
  return datesRange.map((date: Dayjs) => dayjs(date, dateFormat).format(dateFormat));
}