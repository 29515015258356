import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const firebaseInit = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAo80Z-1G6_NHZwK5vaEsH7zGzmweumuPw",
    authDomain: "uboost-frontend.firebaseapp.com",
    projectId: "uboost-frontend",
    storageBucket: "uboost-frontend.appspot.com",
    messagingSenderId: "389513297477",
    appId: "1:389513297477:web:e1e253fdf538a7cc33ed2c",
    measurementId: "G-NKS0G6LFJB",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
};
