import React from 'react';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import { ReactComponent as NothingFoundSVG } from '../../assets/images/404.svg';
import { APP_ROUTES } from '../../data-access';
import { BrandLogo, FormButton } from '../../ui';
import './nothing-found.scss';

export const NothingFound = (): JSX.Element => {
  const navigate = useNavigate();

  const handleBackToHomeClick = (): void => {
    navigate(APP_ROUTES.login);
  }

  return (
    <div className="nothing-found column">
      <BrandLogo />
      <div className="nothing-found__content column">
        <NothingFoundSVG />
        <FormButton className="back-to-home-button" onClick={handleBackToHomeClick}>Back to Home</FormButton>
      </div>
    </div>
  );
}

export default NothingFound;