import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  filter: {
    userId: '',
    period: ''
  }
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: INITIAL_STATE,
  reducers: {
    setUserId: (state = INITIAL_STATE, action: PayloadAction<{ userId: string }>) => {
      return {
        ...state,
       filter: {
          ...state.filter,
         userId: action.payload.userId
       }
      }
    },
    setPeriod: (state = INITIAL_STATE, action: PayloadAction<{ period: string }>) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          period: action.payload.period
        }
      }
    },
    reset: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeAnalyticsActions = analyticsSlice.actions;

// this is for configureStore
const analyticsReducer = analyticsSlice.reducer;

export default analyticsReducer;