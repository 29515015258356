import { ReactNode } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { Spinner } from "../../spinner";
import "./button.scss";

interface IFormButtonProps {
  className?: string;
  type?:
    | "primary"
    | "link"
    | "text"
    | "ghost"
    | "default"
    | "dashed"
    | undefined;
  theme?: "brand" | "red" | "green" | "blue";
  htmlType?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: ReactNode;
}

export const FormButton = ({
  className = "",
  type = "primary",
  theme = "brand",
  htmlType = "button",
  disabled = false,
  loading = false,
  onClick,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  children,
}: IFormButtonProps): JSX.Element => {
  const handleOnClick = (): void => {
    onClick && onClick();
  };

  return (
    <Button
      className={`${className} ${theme} button`}
      htmlType={htmlType}
      disabled={disabled}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {loading && <Spinner size="small" />}
    </Button>
  );
};

FormButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
