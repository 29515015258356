import { ERROR_MESSAGES } from "../../data-access";
import { logoutUser, notify } from "..";

export const handleFailedRequest = (
  errorStatus: number,
  specificErrorText = ERROR_MESSAGES.GENERIC as string,
  errorDetails: { [key: string]: string[] } | undefined = undefined,
  reason = ""
): void => {
  let errorMessage = specificErrorText;

  if (errorStatus === 500) {
    errorMessage = ERROR_MESSAGES.GENERIC;
  }

  if (errorStatus === 422 && errorDetails) {
    errorMessage = Object.values(errorDetails)[0][0];
  }

  if (errorStatus === 400 && reason === ERROR_MESSAGES.ACCESS_CODE_EXPIRED) {
    errorMessage = "Authorization link is expired.";
  }

  notify("error", errorMessage);

  if (errorStatus === 400 && reason === ERROR_MESSAGES.SESSION_ALREADY_SEIZED) {
    logoutUser();
  }
};
