import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { APP_ROUTES } from "./data-access";
import {
  Team,
  Analytics,
  ToolsAndApps,
  ReleaseNotes,
  Plans,
  PrivateRoute,
  ProtectedRoute,
  Login,
  Settings,
  PipedriveAuth,
  UpdateBillingDetailsForm,
  ChoosePaymentMethod,
  NothingFound,
  ExtensionSignOut,
  Billing,
  PaymentProcessing,
  Proposals,
} from "./feature";
import { useUser } from "./hooks";

function App(): JSX.Element {
  const { currentUser } = useUser();

  return (
    <div className="main-container">
      <Routes>
        <Route
          path={APP_ROUTES.home}
          element={<Navigate to={APP_ROUTES.login} />}
        />

        <Route
          path={APP_ROUTES.login}
          element={
            <ProtectedRoute renderCondition={!currentUser}>
              <Login />
            </ProtectedRoute>
          }
        />

        {/* <Route
          path={APP_ROUTES.paymentProcessing}
          element={
            <ProtectedRoute renderCondition={!!currentUser}>
              <PaymentProcessing />
            </ProtectedRoute>
          }
        />
        <Route
          path={APP_ROUTES.settings}
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.team}
          element={
            <PrivateRoute>
              <Team />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.dashboard}
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.toolsAndApps}
          element={
            <PrivateRoute>
              <ToolsAndApps />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.releaseNotes}
          element={
            <PrivateRoute>
              <ReleaseNotes />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.billing}
          element={
            <PrivateRoute isForAdminOnly={true}>
              <Billing />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.updateBilling}
          element={
            <PrivateRoute>
              <UpdateBillingDetailsForm />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.choosePaymentMethod}
          element={
            <PrivateRoute>
              <ChoosePaymentMethod />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.plans}
          element={
            <PrivateRoute>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route path={APP_ROUTES.pipedrive} element={<PipedriveAuth />} />
        <Route
          path={APP_ROUTES.extensionSignOut}
          element={
            <PrivateRoute>
              <ExtensionSignOut />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.proposals}
          element={
            <PrivateRoute>
              <Proposals />
            </PrivateRoute>
          }
        /> */}
        <Route path="*" element={<NothingFound />} />
      </Routes>
    </div>
  );
}

export default App;
