import { storage } from '../storage';

export const uuidStorageKey = 'duid';

const state = {
  uuid: storage.get<string>(uuidStorageKey) || '',
}

const setDeviceUUID = (deviceId: string): void => {
  state.uuid = deviceId;
  storage.set(uuidStorageKey, deviceId);
}

const getUUID = (): string => {
  return state.uuid;
}

export const utilizedDevice = {
  getUUID,
  setDeviceUUID
}