import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDiscount, ILicence, IPlan } from '../../data-access';

interface IBillingState {
  licences: ILicence[],
  plans: IPlan[];
  currentPlan: IPlan | null,
  discount: IDiscount | null
}

const INITIAL_STATE: IBillingState = {
  licences: [],
  plans: [],
  currentPlan: null,
  discount: null
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state = INITIAL_STATE, action: PayloadAction<Partial<IBillingState>>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    reset: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeBillingActions = billingSlice.actions;

// this is for configureStore
const billingReducer = billingSlice.reducer;

export default billingReducer;