import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITeamMemberRecord } from '../../data-access';

const INITIAL_STATE = {
  members: [] as ITeamMemberRecord[]
};

export const teamSlice = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {
    setMembers: (state = INITIAL_STATE, action: PayloadAction<ITeamMemberRecord[]>) => {
      return {
        members: action.payload
      }
    },
    reset: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeTeamActions = teamSlice.actions;

// this is for configureStore
const teamReducer = teamSlice.reducer;

export default teamReducer;