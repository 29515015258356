import React, { FocusEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { APP_ROUTES, VALID_EMAIL_REGEX, VALIDATION_MESSAGES } from '../../../data-access';
import { FormInput, Panel, FormButton } from '../../../ui';
import { notify } from '../../../util';
import './update-billing-details-form.scss';

interface IUpdateBillingDetailsFormData {
  accountName: string;
  email: string;
  address: string;
  country: string;
  city: string;
  postalCode: string;
  vatNumber: string;
}

const onlyLettersAllowedText = 'Only alphabets are allowed for this field';
const onlyLDigitsAllowedText = 'Only numbers are allowed for this field';

const validationScheme = yup.object({
  accountName: yup.string().required(),
  email: yup.string().required().matches(VALID_EMAIL_REGEX, VALIDATION_MESSAGES.INVALID_EMAIL),
  address: yup.string().required(),
  country: yup.string().required().matches(/^[aA-zZ\s]+$/, onlyLettersAllowedText),
  city: yup.string().required().matches(/^[aA-zZ\s]+$/, onlyLettersAllowedText),
  postalCode: yup.number().typeError(onlyLDigitsAllowedText).required(),
  vatNumber: yup.number().typeError(onlyLDigitsAllowedText).required(),
}).required();

export const UpdateBillingDetailsForm = (): JSX.Element => {
  const navigate = useNavigate();

  const form = useForm<IUpdateBillingDetailsFormData>({
    mode: 'onBlur',
    defaultValues: {
      accountName: '',
      email: '',
      address: '',
      country: '',
      city: '',
      postalCode: '',
      vatNumber: '',
    },
    resolver: yupResolver(validationScheme),
  });
  const { register, handleSubmit, setValue, formState: { dirtyFields, errors } } = form;

  const handleFormSubmit = (formFields: IUpdateBillingDetailsFormData): void => {
    console.log(formFields);
    notify('success', 'Billing details have been successfully updated.');
  }

  const handleCancelClick = (): void => {
    navigate(APP_ROUTES.billing, { state: { allowedTransition: true } });
  }

  const isFormBeenModified = Object.keys(dirtyFields).length > 0;

  return (
    <Panel className="update-billing-panel" title="Check your billing details">
      <div className="update-billing">
        <span className="update-billing__note">Your billing details will appear on your invoices</span>
        <form className="update-billing__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="update-billing__fields">
            <FormInput
              {...register('accountName')}
              label="Account name *"
              placeholder="Enter your account name"
              error={errors?.accountName?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('accountName', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
            <FormInput
              {...register('email')}
              label="Email *"
              placeholder="Enter your email address"
              error={errors?.email?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('email', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
            <FormInput
              {...register('address')}
              label="Address *"
              placeholder="Enter your address"
              error={errors?.address?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('address', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
            <FormInput
              {...register('country')}
              label="Country *"
              placeholder="Enter your country"
              error={errors?.country?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('country', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
            <FormInput
              {...register('city')}
              label="City *"
              placeholder="Enter your city"
              error={errors?.city?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('city', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
            <FormInput
              {...register('postalCode')}
              label="Postal code *"
              placeholder="Enter your postal code"
              error={errors?.postalCode?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('postalCode', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
            <FormInput
              {...register('vatNumber')}
              label="VAT number *"
              placeholder="Enter your VAT number"
              error={errors?.vatNumber?.message}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('vatNumber', e?.target?.value, { shouldDirty: true, shouldValidate: true })}
            />
          </div>
          <div className="update-billing__buttons-panel">
            <FormButton onClick={handleCancelClick}>Cancel</FormButton>
            <FormButton htmlType="submit" disabled={!isFormBeenModified}>Save</FormButton>
          </div>
        </form>
      </div>
    </Panel>
  );
}