import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  is_pipedrive_connected: false,
};

export const toolsSlice = createSlice({
  name: 'tools',
  initialState: INITIAL_STATE,
  reducers: {
    setPipedriveConnection: (state = INITIAL_STATE, action: PayloadAction<{ is_connected: boolean }>) => {
      return {
        is_pipedrive_connected: action.payload.is_connected
      }
    },
    reset: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeToolsActions = toolsSlice.actions;

// this is for configureStore
const toolsReducer = toolsSlice.reducer;

export default toolsReducer;