import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { storeMenusActions } from '../../store/slices/menus';

interface IUseMobileMenuReturn {
  isMenuOpened: boolean,
  toggleBurgerMenu: () => void;
}

export const useMobileMenu = (): IUseMobileMenuReturn => {
  const isMenuOpened = useSelector((state: RootState) => state.menus.mobile);

  const dispatch = useDispatch();

  useEffect(() => {
    const sidebarEl = document.getElementsByClassName('sidebar')[0];

    if (sidebarEl) {
      if (isMenuOpened) {
        sidebarEl.classList.add('active');
        document.body.classList.add('menu-opened');
      } else {
        sidebarEl.classList.remove('active');
        document.body.classList.remove('menu-opened');
      }
    }
  }, [isMenuOpened])

  const toggleBurgerMenu = (): void => {
    dispatch(storeMenusActions.setMenu({ mobile: !isMenuOpened }));
  }

  return {
    isMenuOpened,
    toggleBurgerMenu,
  }
}