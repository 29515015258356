import { appStateKey, store } from "../../store";
import { storeAuthActions } from "../../store/slices/auth";
import { storeAnalyticsActions } from "../../store/slices/analytics";
import { storeBillingActions } from "../../store/slices/billing";
import { storeSettingsActions } from "../../store/slices/settings";
import { storeTeamActions } from "../../store/slices/team";
import { storeToolsActions } from "../../store/slices/tools";
import { cookie, storage } from "..";

export const logoutUser = (): void => {
  store.dispatch(storeAuthActions.resetAuthData());
  store.dispatch(storeSettingsActions.resetSettingsData());
  store.dispatch(storeTeamActions.reset());
  store.dispatch(storeToolsActions.reset());
  store.dispatch(storeAnalyticsActions.reset());
  store.dispatch(storeBillingActions.reset());
  storage.remove(appStateKey);
  cookie.remove("exSM", `domain=${window.location.hostname};path=/`);
  cookie.remove("extFlag", `domain=${window.location.hostname};path=/`);
};
