import React, { FocusEvent } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { VALID_EMAIL_REGEX, VALIDATION_MESSAGES } from '../../../data-access';
import { FormInput, FormButton } from '../../../ui/form';
import './invite-team-member-form.scss';

interface IFormInitialData {
  email: string;
}

const validationScheme = yup.object({
  email: yup.string().required().matches(VALID_EMAIL_REGEX, VALIDATION_MESSAGES.INVALID_EMAIL)
}).required();

interface IInviteTeamMemberFormProps {
  isLoading?: boolean,
  onSuccess: (formFields: IFormInitialData) => void;
}

export const InviteTeamMemberForm = ({ isLoading = false, onSuccess }: IInviteTeamMemberFormProps): JSX.Element => {
  const form = useForm<IFormInitialData>({
    mode: 'onBlur',
    defaultValues: { email: '' },
    resolver: yupResolver(validationScheme),
  });
  const { control, handleSubmit, setValue, formState: { errors } } = form;

  return (
    <div className="invite-team-member">
      <p>We will send you an invitation link to join the team to the following email:</p>
      <form className="invite-member-form" onSubmit={handleSubmit(onSuccess)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { name, onBlur, value, ref } }) => (
            <FormInput
              ref={ref}
              name={name}
              placeholder="Email"
              error={errors?.email?.message}
              value={value}
              disabled={isLoading}
              onBlur={onBlur}
              onChange={(e: FocusEvent<HTMLInputElement>) => setValue('email', e?.target?.value)}
            />
          )}
        />
        <FormButton loading={isLoading} disabled={isLoading} htmlType="submit">Invite</FormButton>
      </form>
    </div>
  );
}

InviteTeamMemberForm.propTypes = {
  isLoading: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
};