const get = (name: string): string => {
  const cookieMap = {} as Record<string, string>;

  document.cookie.split(';').forEach((item: string) => {
    const [key, value] = item.split('=');

    cookieMap[key.trim()] = value;
  });

  return cookieMap[name];
};

const set = (name: string, value = ''): void => {
  document.cookie = `${name}=${value};domain=${window.location.hostname};path=/`;
};

const remove = (name: string, attrs = ''): void => {
  document.cookie = `${name}=;max-age=0;${attrs}`;
};

export const cookie = {
  get,
  set,
  remove,
};
