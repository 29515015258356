import React from 'react';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';

import './date-range-picker.scss';

const { RangePicker } = DatePicker;

interface IDateRangePickerProps {
  className?: string;
  inputReadOnly?: boolean;
  showTime?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  format?: string;
  defaultValue?: [Dayjs | null, Dayjs | null]
  onChange: (value: any) => void;
}

export const DateRangePicker = ({
  className = '',
  inputReadOnly = true,
  showTime = false,
  disabled = false,
  allowClear = true,
  defaultValue = [null, null],
  format = 'DD/MM/YYYY',
  onChange
}: IDateRangePickerProps): JSX.Element => {
  return (
    <RangePicker
      className={`date-range-picker ${className}`}
      inputReadOnly={inputReadOnly}
      showTime={showTime}
      defaultValue={defaultValue}
      allowClear={allowClear}
      format={format}
      disabled={disabled}
      onChange={onChange}
    />
  );
}