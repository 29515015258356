import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  mobile: false,
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState: INITIAL_STATE,
  reducers: {
    setMenu: (state = INITIAL_STATE, action: PayloadAction<Partial<{ [key: string]: boolean }>>) => {
      console.log('STATE = ', {
        state,
        payload: action.payload
      });
      return ({
        ...state,
        ...action.payload
      })
    },
    reset: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeMenusActions = menusSlice.actions;

// this is for configureStore
const menusReducer = menusSlice.reducer;

export default menusReducer;