import { FocusEventHandler, forwardRef, ForwardedRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { capitalize } from '../../../util';
import './textarea.scss';

const { TextArea } = Input;

const baseClass = 'field-textarea';

interface IFormTextareaProps {
  onChange: FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  label?: string;
  name?: string;
  error?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  rows?: number;
  maxLength?: number;
  value?: string;
}

export const FormTextarea = forwardRef(({
  value,
  error = '',
  name,
  label,
  placeholder,
  disabled = false,
  onBlur,
  onChange,
  rows = 4,
  maxLength = 250,
  className = '' }: IFormTextareaProps,
  ref: ForwardedRef<any>
): JSX.Element => {
  return (
    <div className={baseClass}>
      {label && <label className={`${baseClass}__label`}>{label}</label>}
      <TextArea
        className={`${className} form-textarea`}
        ref={ref}
        rows={rows}
        maxLength={maxLength}
        name={name}
        status={error ? 'error' : undefined}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        value={value}
        autoSize={{ minRows: rows }}
      />
      {error && <p className={`${baseClass}__error`}>{capitalize(error)}</p>}
    </div>
  );
});

FormTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool
};

export default FormTextarea;