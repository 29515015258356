import { useEffect } from 'react';
import { ClientJS } from 'clientjs';
import { utilizedDevice } from '../../util';

const client = new ClientJS();

export const useUniqueDeviceId = (): void => {
  useEffect(() => {
    if (!utilizedDevice.getUUID()) {
      const os = client.getOS();
      const version = client.getOSVersion();
      const language = client.getLanguage();
      const timezone = client.getTimeZone();
      const resolution = client.getAvailableResolution();
      const canvas = client.getCanvasPrint();
      const fonts = client.getFonts();
      const plugins = client.getPlugins();
      const userAgent = client.getUserAgent();

      const cypher = client.getCustomFingerprint(os, version, language, timezone, resolution, canvas, fonts, plugins, userAgent);

      utilizedDevice.setDeviceUUID(`${cypher}`);
    }
  }, []);
}