import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaLongArrowAltRight } from 'react-icons/fa';
import * as yup from 'yup';
import { FormButton, FormSelect } from '../../../ui';
import './contacts.scss';
import {Alert} from "antd";

interface IContactsFormData {
  jobId: string;
  chatId: string;
}

interface IContactsProps {
  showMWarningBeforeFields?: boolean;
}

const validationScheme = yup.object({
  jobId: yup.string().required(),
  chatId: yup.string().required()
}).required();

export const Contacts = ({ showMWarningBeforeFields = false }: IContactsProps): JSX.Element => {
  const [selectedJobId, setSelectedJobId] = useState<string>('');
  const [selectedChatId, setSelectedChatId] = useState<string>('');

  const form = useForm<IContactsFormData>({
    mode: 'all',
    defaultValues: {
      jobId: '',
      chatId: ''
    },
    resolver: yupResolver(validationScheme),
  });
  const { register, handleSubmit, setValue, formState: { errors, dirtyFields } } = form;

  const handleFormSubmit = (formFields: IContactsFormData): void => {
    console.log('formFields = ', formFields);
  }

  const handleJobIdChange = (jobId: string): void => {
    setValue('jobId', jobId, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    setSelectedJobId(jobId);
  }

  const handleChatIdChange = (chatId: string): void => {
    setValue('chatId', chatId, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    setSelectedChatId(chatId);
  }

  const isFormValid = Object.keys(dirtyFields).length > 0 && Object.keys(errors).length === 0;

  return (
    <div className="contacts">
      <div className="contacts__heading">
        <span className="contacts__heading-title">upwork</span>
        <span className="contacts__heading-title">pipedrive</span>
      </div>
      <div className="contacts__content">
        <h2 className={"contacts__title"}>Personal information</h2>
        {showMWarningBeforeFields && (
          <Alert message="In the Settings the option to create a contact (not a lead) on the first synchronization is enabled." type="warning" />
        )}
        <form className="contacts__form" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="contacts__field">
            <span className="contacts__label">Job ID</span>
            <div className="contacts__field-wrap">
              <FaLongArrowAltRight />
              <FormSelect
                {...register('jobId')}
                placeholder="Please select job id"
                error={errors?.jobId?.message}
                value={selectedJobId}
                options={[{ value: '', label: '' }, { value: '1', label: 'Job 1' }]}
                onChange={handleJobIdChange}
              />
            </div>
          </div>

          <div className="contacts__field">
            <span className="contacts__label">Chat ID</span>
            <div className="contacts__field-wrap">
              <FaLongArrowAltRight />
              <FormSelect
                {...register('chatId')}
                placeholder="Please select chat id"
                error={errors?.chatId?.message}
                value={selectedChatId}
                options={[{ value: '', label: '' }, { value: '1', label: 'Chat 1' }]}
                onChange={handleChatIdChange}
              />
            </div>
          </div>
          <FormButton className="contacts__button" type="primary" htmlType="submit" disabled={!isFormValid}>Save</FormButton>
        </form>
      </div>
    </div>
  );
}